import {  Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { CustomButton } from '../../components/helpers'
import Hero from '../../assets/images/man.jpg'
import img from '../../assets/images/img.svg'
import img1 from '../../assets/images/img1.svg'
import img2 from '../../assets/images/img2.svg'
import { useNavigate } from 'react-router-dom'

const benefits = [
    {
        img: img,
        title: "Adjustable Hours",
        description: "Take charge of your time and reduce fixed expenses, beginning with 40 hours a month."
    },
    {
        img: img1,
        title: "Dynamic Commitment",
        description: "Swiftly resize your team, making necessary adjustments in a matter of weeks."
    },
    {
        img: img2,
        title: "Personalized Support",
        description: "Work with our supportive team for customized help under different departments."
    }
];

export const PricingCard = () => {
    const navigate = useNavigate()
    return (
        <section className='pricing_card'>
            <h1 >Customizable Pricing Plans</h1>
            <p>Simple and Flexible Terms, Perfectly Matched to Your Business.</p>
            <div className='box'>
                <h2 >£10.00 - £20.00</h2>
                <p>
                    Per resources, per hour
                </p>
                <Stack direction={{ xs: 'column', sm: 'row' }}
                    sx={{ justifyContent: "center", alignItems: "end", gap: 2 }}>
                    <CustomButton text={"FIND RESOURCES"} className={" large bg-default"} onClick={() => navigate("/signup")} />
                    <CustomButton text={"TALK TO AN EXPERT"} className={" large bg-default"} onClick={() => navigate("/expert")} />
                    <Typography
                        onClick={() => navigate('/pricing')}
                        sx={{ cursor: "pointer" }}
                    >
                        <span style={{ textDecoration: "underline", fontSize: "16px", fontWeight: "600", }}>Teckable vs Alternatives</span>
                        <span style={{ textDecoration: "none", fontSize: "16px", fontWeight: "600", }}>&nbsp;(Pricing)</span>
                    </Typography>
                </Stack>
                <Stack direction={"row"} spacing={2} mt={3} sx={{ justifyContent: { md: "start", xs: "center" }, alignItems: { xs: "center", md: "start" }, flexWrap: "wrap" }}>
                    <p className='banner'>✓ 100+ Resource network</p>
                    <p className='banner'>✓ 5 days Hiring duration</p>
                    <p className='banner'>✓ 3+ Years Resource retention</p>
                </Stack>
                <p className='pricing-advantages'>
                    No hidden fees • Includes HR and admin support
                </p>
                <img src={Hero} alt="Hero" className='hero ' />
            </div>


            <Grid container className='benefits' spacing={2}>
                {benefits.map((item) => (
                    <Grid item xs={12} key={item.title} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <img src={item.img} alt="Icon" />
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </Grid>
                ))}

            </Grid>

        </section>
    )
}
