import React from 'react'
import ResponsiveAppBar from '../../components/header'
import { Box, Container } from '@mui/material'
import './_pricing.scss'
import { PricingCard } from './pricing_card'
import { Footer } from '../../components/footer'
import { TraditionalHiring } from './traditional_hiring'
import { Search } from './search'
import { Helmet } from 'react-helmet-async';

const Pricing = () => {
    return (
        <>
            <Helmet>
                <title>Flexible Pricing Plans | Teckable</title>
            </Helmet>
            <Box sx={{ backgroundColor: "#5258fb" }}>
                <Box>
                    <ResponsiveAppBar />
                </Box>
                <Container maxWidth="lg" sx={{ pt: 10, pb: 5 }}>
                    <PricingCard />
                </Container>

            </Box>
            <TraditionalHiring />
            <Search />
            <Footer />
        </>
    )
}

export default Pricing