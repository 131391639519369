import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './_index.scss';
import logo from '../../assets/logo/logo.png';
import SendIcon from '@mui/icons-material/Send';
import MuiPhoneNumber from 'mui-phone-number';
import PropTypes from 'prop-types';
import { red } from '@mui/material/colors';
import ResponsiveAppBar from '../../components/header';
import { Footer } from '../../components/footer';

import { object, string, boolean } from 'yup';
import { useNavigate } from 'react-router-dom';
import SpringModal from '../../components/modal';
import { Helmet } from 'react-helmet-async';;

const userSchema = object({
  phoneNumber: string().optional(),
  email: string().email('Invalid email address').required('Email is required'),
  name: string().required('Name is required'),
  engagementLength: string().required('Engagement length is required'),
  employeeCount: string().required('Employee count is required'),
  consent: boolean().optional()  // Consent is handled separately
});


const empCounts = [
  '2-10 employees',
  '11-50 employees',
  '51-200 employees',
  '201-500 employees',
  '501-2000 employees',
  '2001-5000 employees',
  '5001-10000 employees',
  '10001+ employees'
];
const engagementArray = ['More than 6 months', '3 to 6 months', '1 to 3 months', '1 to 4 weeks', 'I’m not really sure'];

const SignUp = () => {
  const [data, setData] = useState({});
  const [completionPercentage, setCompletionPercentage] = useState((1 / 6) * 100);
  const [errMsg, setErrMsg] = useState({})
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isSuccess, setIsSuccess] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    const completedFields = Object.keys(data).length;
    setCompletionPercentage((completedFields + 1) / 6 * 100);
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleSuccess = () => {
    setOpen(false)
    navigate('/')
  }
  const handleSubmit = async () => {
    try {
      await userSchema.validate(data, { abortEarly: false });

      if (!data.consent) {
        setErrMsg((prevErrMsg) => ({
          ...prevErrMsg,
          consent: 'You must agree to the privacy and cookie policies',
        }));
        return;
      }

      setErrMsg({});

      // API call
      const response = await fetch('https://optimists-services-india-test.azurewebsites.net/api/notifications/submit-enquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setIsSuccess(true)
        setOpen(true)
      } else {
        setIsSuccess(false)
        setOpen(true)
      }
    } catch (error) {
      if (error.inner) {
        const validationErrors = error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {});
        setErrMsg(validationErrors);
      } else {
        console.error('Failed to send email:', error);
      }
    }
  };




  return (
    <>
      <Helmet>
        <title>Sign Up | Find Your Perfect Match with Teckable</title>
      </Helmet>
      <Box>
        <ResponsiveAppBar />
      </Box>
      <Box sx={{ backgroundColor: '#fff !important', height: '100%' }} className="signup">
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12} md={5.5} className="first">
            <img src={logo} alt="Logo" />
            <h1>Find your perfect match</h1>
            <p>Answer 6 short questions to help us understand your needs.</p>
          </Grid>
          <Grid item xs={12} md={6.5} sx={{ px: { md: 10, xs: 2 }, py: { md: 5, xs: 3 }, position: 'relative', overflowY: 'auto', maxHeight: { md: '100vh', xs: "100%" } }}>
            <InputComponent
              data={data}
              handleInputChange={handleInputChange}
              setData={setData}
              completionPercentage={completionPercentage}
              empCounts={empCounts}
              engagementArray={engagementArray}
              handleSubmit={handleSubmit}
              errMsg={errMsg}
            />
          </Grid>
        </Grid>
      </Box>
      <Footer />
      <SpringModal handleClose={handleClose} handleSuccess={handleSuccess} isSuccess={isSuccess} open={open} handleOpen={handleOpen} />
    </>
  );
};

export default SignUp;


export const InputComponent = ({ btnName, errMsg, data, handleInputChange, setData, completionPercentage, empCounts, engagementArray, handleSubmit }) => {
  const [consent, setConsent] = useState(data.consent || false);
  const navigate = useNavigate()
  const handleConsentChange = (event) => {
    const { checked } = event.target;
    setConsent(checked);
    setData((prevData) => ({ ...prevData, consent: checked }));
  };

  return (
    <div className="input-fields">
      <FormControl fullWidth>
        <h2>What's your company size?</h2>
        <Select
          sx={{ border: '2px solid' }}
          labelId="company-size-select-label"
          id="company-size-select"
          onChange={handleInputChange}
          name="employeeCount"
          value={data.employeeCount || ''}
        >
          {empCounts.map((empCount) => (
            <MenuItem key={empCount} value={empCount}>
              {empCount}
            </MenuItem>
          ))}
        </Select>
        {errMsg.employeeCount && <Typography color={red[500]}>{errMsg.employeeCount}</Typography>}

        <h2>How long will the engagement last?</h2>
        <RadioGroup
          aria-labelledby="engagement-length-radio-group-label"
          name="engagementLength"
          value={data.engagementLength || ''}
          onChange={handleInputChange}
        >
          <Grid container spacing={2}>
            {engagementArray.map((item) => (
              <Grid item xs={12} md={6} key={item}>
                <Box className="last">
                  <FormControlLabel value={item} control={<Radio />} label={item} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
        {errMsg.engagementLength && <Typography color={red[500]}>{errMsg.engagementLength}</Typography>}

        <h2>We’d like to get in touch and hear about your needs</h2>
        <Typography sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}>Name</Typography>
        <TextField
          id="name-input"
          sx={{ width: '80%', borderRadius: 5 }}
          size="medium"
          variant="outlined"
          name="name"
          value={data.name || ''}
          onChange={handleInputChange}
        />
        {errMsg.name && <Typography color={red[500]}>{errMsg.name}</Typography>}

        <Typography sx={{ mt: 2, color: '#344054', fontSize: '14px', fontWeight: 500 }}>Your company email</Typography>
        <TextField
          id="email-input"
          sx={{ width: '80%' }}
          size="medium"
          variant="outlined"
          required
          type='email'
          name="email"
          value={data.email || ''}
          onChange={handleInputChange}
        />
        {errMsg.email && <Typography color={red[500]}>{errMsg.email}</Typography>}


        <Typography sx={{ mt: 2, color: '#344054', fontSize: '14px', fontWeight: 500 }}>Phone number (Optional)</Typography>
        <MuiPhoneNumber
          defaultCountry="gb"
          variant="outlined"
          sx={{ width: '80%' }}
          name="phoneNumber"
          value={data.phoneNumber || ''}
          onChange={(value) => setData((prevData) => ({ ...prevData, phoneNumber: value }))}
        />
        <Stack direction="row" alignItems="start" spacing={2} my={2}>
          <Checkbox
            size="small"
            checked={consent}
            onChange={handleConsentChange}
            sx={{ p: 0 }}
          />
          <label htmlFor="checkbox" className="checkbox-label">
            I agree to let Teckable use my personal data to create an offer for their services.
            Read our <span onClick={() => navigate('/privacy-policy')}>privacy policy</span> and <span onClick={() => navigate('/cookie-policy')}>cookie policy</span>.
          </label>
        </Stack>
        {errMsg.consent && <Typography color={red[500]}>{errMsg.consent}</Typography>}
      </FormControl>

      <div className="button-row">
        <Button
          variant="contained"
          sx={{ borderRadius: '5px', textDecoration: 'none' }}
          endIcon={<SendIcon />}
          onClick={handleSubmit}
        >
          {btnName || "Submit"}
        </Button>
      </div>
    </div>
  );
};

InputComponent.propTypes = {
  data: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  empCounts: PropTypes.array.isRequired,
  engagementArray: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  btnName: PropTypes.string,
  errMsg: PropTypes.object.isRequired
};
