import { Box, Container, Divider, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './_index.scss'
import Logo from '../assets/logo/logo.png'
import { useNavigate } from 'react-router-dom';
import XIcon from '@mui/icons-material/X';
const language = [{ code: "US", text: "English" }, { code: "DE", text: "Deutsch" }]

export const Footer = () => {
    const [anchorLan, setAnchorLan] = React.useState(null);
    const navigate = useNavigate()
    const openLan = Boolean(anchorLan);
    const handleClickLan = (event) => {
        setAnchorLan(event.currentTarget);
    };
    const handleCloseLan = () => {
        setAnchorLan(null);
    };
    return (
        <footer className="footer">
            <Container maxWidth={"lg"} sx={{ pt: { xs: 5, md: 8 }, pb: 5 }}>
                <Grid container spacing={{ xs: 4, md: 3 }} >
                    <Grid item xs={12} md={4}>
                        <Stack rowGap={2} className="contact-info">
                            <Stack direction={"row"} alignItems={"center"}>
                                <img src={Logo} alt='Teckable' onClick={() => navigate('/')} style={{ height: "30px", cursor: "pointer", }} />
                            </Stack>
                            <Typography>
                                BRIGHT FUTURE CONSULTANCY LTD
                            </Typography>

                            <Typography mt={1}>128 City Road,
                                London,England
                                EC1V 2NX<br />
                                UNITED KINGDOM</Typography>
                            <Typography>Company Number - 12520976
                            </Typography>
                            <Typography>
                                VAT Number - 348031416
                            </Typography>
                            <Stack direction={"row"} spacing={2}>
                                <a href='https://www.linkedin.com/company/teckable/' target='_blank' rel="noopener noreferrer">
                                    <LinkedInIcon sx={{ color: '#FFF' }} />
                                </a>
                                <a href="https://x.com/TeckableSkills" target="_blank" rel="noopener noreferrer">
                                    <XIcon sx={{ color: '#FFF' }} />
                                </a>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2} className='section'>
                            <Grid item xs={6} sm={3} >
                                <Stack>
                                    <h4>Why Teckable</h4>
                                    <p onClick={() => navigate("/why")}>How it works</p>
                                    <p onClick={() => navigate("/case-studies")}>Case Studies</p>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Stack>

                                    <h4>Pricing</h4>
                                    <p onClick={() => navigate("/pricing")}>Teckable vs alternatives</p>
                                    <p onClick={() => navigate("/faq")}>FAQs</p>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Stack>

                                    <h4>Company</h4>
                                    <p onClick={() => navigate("/about-us")}>About us</p>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Stack>
                                    <h4>Resources</h4>
                                    <p onClick={() => navigate("/skills-explore")}>Skills Explorer</p>
                                    <p ><a href='https://teckable.com/skills/articles/'>Learning Hub</a></p>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2, border: "1px solid #1d2939" }} />
                <Box sx={{ display: { xs: "block", md: "flex" }, justifyContent: "space-between" }}>
                    <Stack direction={"row"} sx={{ alignItems: "center", cursor: "pointer" }} aria-label="more"
                        id="long-button"
                        aria-controls={openLan ? 'long-menu' : undefined}
                        aria-expanded={openLan ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClickLan}>
                        <LanguageIcon fontSize='medium' sx={{ cursor: "pointer" }} />
                        <KeyboardArrowDownIcon fontSize='small' />
                    </Stack>
                    <Stack direction={"row"} sx={{ mt: { xs: 2, md: 0 } }} spacing={2} className='policy' >
                        <p onClick={() => navigate('/privacy-policy')}>Privacy Policy   </p>
                        <p onClick={() => navigate('/cookie-policy')}>Cookie Policy</p>
                    </Stack>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorLan}
                        open={openLan}
                        onClose={handleCloseLan}
                    >
                        {language.map((option) => (
                            <MenuItem key={option} onClick={handleCloseLan}>
                                <Stack gap={1} direction={"row"} sx={{ alignItems: "center" }} >
                                    <img
                                        loading="lazy"
                                        width="20"
                                        srcSet={`https://flagcdn.com/w40/${option?.code.toLowerCase()}.png 2x`}
                                        src={`https://flagcdn.com/w20/${option?.code.toLowerCase()}.png`}
                                        alt=""
                                    /><Typography className='title' sx={{ color: "#000" }}>{option.text}</Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

            </Container>

        </footer>
    )
}


