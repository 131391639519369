import React from 'react'
import ResponsiveAppBar from '../../components/header'
import { Box, } from '@mui/material'
import { Footer } from '../../components/footer'
import { AboutUsFirst } from './about-us'
import './_index.scss'
import { ContactUs } from '../../components/contact-us'
import { OurValues } from './values'
import { Helmet } from 'react-helmet-async';

const AboutUs = () => {
    return (
        <>
            <Helmet>
                <title>About Us | Discovering Hidden Talent Worldwide Since 2012 | Teckable</title>
            </Helmet>
            <Box sx={{ backgroundColor: "#5258fb" }}>
                <Box>
                    <ResponsiveAppBar />
                </Box>
                <AboutUsFirst />
            </Box>
            {/* <Team /> */}
            <OurValues />
            {/* <Box py={7} bgcolor={"#ffffff"} >
                <Container maxWidth="lg" >
                    <Box className="contact-us-card">
                        <Grid container spacing={{ xs: 0, md: 3 }} alignItems={"center"} >
                            <Grid item xs={12} md={8}>
                                <h2 dangerouslySetInnerHTML={{ __html: "Inspired by our mission? We’d love to hear from you..." }}></h2>
                            </Grid>
                            <Grid md={4} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                <CustomButton text={"See open positions"} className={"secondary large bg-default"} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box> */}
            {/* <Global /> */}
            <ContactUs />
            <Footer />
        </>
    )
}

export default AboutUs