import { Container } from '@mui/material'
import React from 'react'
import { CustomButton } from '../../components/helpers'
import { useNavigate } from 'react-router-dom'

export const TraditionalHiring = () => {
    const navigate = useNavigate()
    return (
        <section className='traditional_hiring'>
            <Container maxWidth={"lg"}>
                <h2>Teckable vs  traditional Hiring</h2>
                <p> Achieve more with remote talent while maintaining financial control.</p>
                <div className="table-wrapper">
                    <div className="table">
                        <div className="row row-header">
                            <div className="column"></div>
                            <div className="column highlighted"><span>Teckable</span></div>
                            <div className="column"><span>Traditional agency</span></div>
                            <div className="column"><span>Traditional employment</span></div>
                        </div>
                        <div className="row">
                            <div className="column"><span>Cost per hour</span></div>
                            <div className="column highlighted"><span>£10 - £20</span></div>
                            <div className="column"><span>£70 - £150</span></div>
                            <div className="column"><span>Higher</span></div>
                        </div>
                        <div className="row">
                            <div className="column highlighted"><span>Recruitment cost</span></div>
                            <div className="column "><span>£0</span></div>
                            <div className="column"><span>£0</span></div>
                            <div className="column"><span>£10,000 — £20,000</span></div>
                        </div>
                        <div className="row">
                            <div className="column"><span>Time to hire</span></div>
                            <div className="column highlighted"><span>5 Days</span></div>
                            <div className="column"><span>1 — 2 months</span></div>
                            <div className="column"><span>1 — 4 months</span></div>
                        </div>
                        <div className="row">
                            <div className="column"><span>Quality-assured</span></div>
                            <div className="column highlighted"><span>Always</span></div>
                            <div className="column"><span>Sometimes</span></div>
                            <div className="column"><span>-</span></div>
                        </div>
                        <div className="row">
                            <div className="column"><span>Termination costs</span></div>
                            <div className="column highlighted"><span>-</span></div>
                            <div className="column"><span>Typically high</span></div>
                            <div className="column"><span>Typically high</span></div>
                        </div>
                        <div className="row cta-wrapper">
                            <div className="column"></div>
                            <div className="column">
                            <CustomButton  text={"FIND RESOURCES"}  className={"bg-default large"} onClick={() => navigate("/signup")} />
                            </div>
                            <div className="column"></div>
                            <div className="column"></div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}


